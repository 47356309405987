export const AdjustStartPageUrl = 'https://app.adjust.com/112zb57z_116hd4yn'

export const supportedLanguages = {
  sv: {
    onboardingQuestionnaire:
      'https://onboarding.lifesum.com/weight-management-sw?utm_source=Lifesum&utm_medium=organic&utm_campaign=mainpage',
  },
  en: {
    onboardingQuestionnaire:
      'https://onboarding.lifesum.com/weight-management-us?utm_source=Lifesum&utm_medium=organic&utm_campaign=mainpage',
  },
  de: {
    onboardingQuestionnaire:
      'https://onboarding.lifesum.com/weight-management-de?utm_source=Lifesum&utm_medium=organic&utm_campaign=mainpage',
  },
  no: {
    onboardingQuestionnaire:
      'https://onboarding.lifesum.com/weight-management-no?utm_source=Lifesum&utm_medium=organic&utm_campaign=mainpage',
  },
  da: {
    onboardingQuestionnaire:
      'https://onboarding.lifesum.com/weight-management-dk?utm_source=Lifesum&utm_medium=organic&utm_campaign=mainpage',
  },
  nl: {
    onboardingQuestionnaire:
      'https://onboarding.lifesum.com/weight-management-nl?utm_source=Lifesum&utm_medium=organic&utm_campaign=mainpage',
  },
}
